import httpClient from "@/shared/services/http-client";
import userService, { USER_ROLES } from "@/shared/services/user-service";
import { logger } from "@/shared/services/common";
import { defineStore } from "pinia";
import { DEFAULT_STATUS_CARDS } from "@/shared/services/ticket-ui-service";

export const useConfigStore = defineStore("configStore", {
    state: () => ({
        /**@type {import("@/types").AppConfig} */
        settings: {},
        customer: null,
        isCustomerSet: false,
        /**@type {import("@/types").LocalUser} */
        userDetails: undefined,
        loadingUser: false,
        customerPromise: null,
    }),
    getters: {
        client: (state) => state.settings,
        userData: (state) => state.userDetails,
    },
    actions: {
        async getCustomer() {
            if (this.isCustomerSet) {
                return this.customer;
            }

            if (this.customerPromise) {
                return this.customerPromise;
            }

            this.customerPromise = (async () => {
                try {
                    const response = await httpClient.get("/iotapp/customer-logo/");
                    const customer = response.data;
                    this.customer = customer;

                    const configData = await this.loadSettings();
                    this.settings = configData;
                    this.isCustomerSet = true;
                    return this.customer;
                } catch (error) {
                    console.error("Error setting customer:", error);
                    return null;
                } finally {
                    this.customerPromise = null;
                }
            })();

            return this.customerPromise;
        },
        async loadSettings() {
            try {
                const response = await httpClient.get(`/iotapp/customer-settings/`);
                return response.data;
            } catch (error) {
                console.error("Error fetching setting!\n", error);
                return {};
            }
        },
        async reloadSettings() {
            const configData = await this.loadSettings();
            this.settings = configData;
        },
        async setUserDetails() {
            try {
                this.loadingUser = true;
                this.userDetails = await userService.getCurrentUser();
                return this.userDetails;
            } finally {
                this.loadingUser = false;
            }
        },
        isCustomerAdmin() {
            return this.userData?.userRole?.includes(USER_ROLES.customeradmin) ?? false;
        },
        /**@param {typeof this.settings} data  */
        updateConfig(data) {
            this.settings = data;
        },
        /**@param {'asset'|'ticket'} key  */
        getByKey(key) {
            return this.client?.[`${key}Config`]?.alias;
        },
        getWhiteListDomains() {
            return this.settings?.appConfig?.domainWhitelist || [];
        },
        getAssetAlias(fallback = "Site") {
            let label = this.settings?.assetConfig?.alias;
            return label || fallback;
        },
        getTreeAccess() {
            const showAssetTree = this.settings?.treeConfig?.showAssetTree || false;
            const showDeviceTree = this.settings?.treeConfig?.showDeviceTree || false;
            const showEquipmentTree = this.settings?.treeConfig?.showEquipmentTree || false;
            return { showAssetTree, showDeviceTree, showEquipmentTree };
        },
        getAssetAPILimitCall(fallback = 500) {
            let assetTreeAPILimit = parseInt(this.settings?.treeConfig?.assetTreeAPILimit);
            return assetTreeAPILimit || fallback;
        },
        getAssetAPICallInterval(fallback = 10) {
            // in seconds
            let assetTreeAPICallInterval = parseInt(this.settings?.treeConfig?.assetTreeAPICallInterval);
            return assetTreeAPICallInterval || fallback;
        },
        getDeviceLimitUnderAsset(fallback = 15) {
            let deviceLimitUnderAsset = parseInt(this.settings?.treeConfig?.deviceLimitUnderAsset);
            return deviceLimitUnderAsset || fallback;
        },
        getStatusPriority() {
            let statusPriority = this.settings?.treeConfig?.statusPriority || {};
            let defaultStatus = this.settings?.treeConfig?.statusPriorityDefault || "#009900";
            return { statusPriority, defaultStatus };
        },
        getTicketAlias(fallback = "Ticket") {
            let label = this.settings?.ticketConfig?.alias;
            return label || fallback;
        },
        getStatusCards() {
            const r = this.settings?.ticketConfig?.statusCardItems;
            return r || DEFAULT_STATUS_CARDS;
        },
        getTicketConfig() {
            return (
                this.settings.viewsConfig?.ticketView || {
                    addTicket: true,
                    attachments: true,
                    history: true,
                    rating: true,
                    watcher: true,
                }
            );
        },
        /**@param {import("@/types").TicketUITypes} key  */
        getTicketTypeAlias(key) {
            try {
                let alias = this.settings.ticketConfig.typeAlias[key];
                if (alias) alias += ` ${this.getTicketAlias()}s`;
                return alias;
            } catch (error) {
                return undefined;
            }
        },
        /**@param {import("@/types").TicketTableHeaderField[]} items  */
        updateTableHeaders(items) {
            try {
                const config = this.settings.ticketConfig.tableHeaders ?? {};
                items.forEach((v) => {
                    const isEnabled = config[v.key] ?? v.visible;
                    v.visible = isEnabled;
                });
            } catch (error) {
                logger.error("Error when getting table headers from client config", { error });
            }
        },
        /**@param {import("@/types").TicketType} type  */
        getOrderBy(type) {
            const defaultValue = "-updatedAt";
            if (type === "AIAlarmTicket") {
                return this.settings.ticketConfig.orderBy || defaultValue;
            } else {
                return defaultValue;
            }
        },
        /**@param {import("@/types").AttendanceScannerOptions} view  */
        showAttendanceScanner(view) {
            return this.settings.viewsConfig.attendanceScanner?.[view] ?? false;
        },

        /**@param {import("@/types").ConfigOptions} configName  */
        getMandatoryFields(configName) {
            if (!configName) {
                return {};
            }
            return this.settings?.[configName]?.mandatoryFields ?? {};
        },
        /**@param {import("@/types").UnitOptions} unit  */
        getUnit(unit, fallback) {
            return this.settings?.appConfig?.units?.[unit] ?? fallback;
        },
        // to get mobile number digit limit
        getMobileNumberDigit() {
            return this.settings?.appConfig?.mobileNumberLimit ?? 10;
        },
        getGroupByDayConfig() {
            return (
                this.settings?.viewsConfig?.ticketsGroupByDayButton ?? {
                    show: true,
                    value: true,
                }
            );
        },
        getTicketNotificationSound() {
            return this.settings?.ticketConfig?.ticketNotificationSound ?? false;
        },
        getCameraRecordConfig() {
            const DEFAULT = {
                durationInSeconds: 60,
                gridRows: 3,
                gridColumns: 2,
            };
            return this.settings?.cameraConfig?.recordings ?? DEFAULT;
        },
        getFormConfig() {
            return this.settings?.formConfig || {};
        },
        getStatusOnCommentConfig() {
            return this.settings?.ticketConfig?.statusOnComment ?? {};
        },
        getTicketExportConfig() {
            const defaultExportOption = {
                maxRowsToExport: 10000,
                monthRange: 6,
                exportVisibleColumnsOnly: false,
            };
            const exportFields = this.settings?.ticketConfig?.exportFields || {};
            const exportOptions = this.settings?.ticketConfig?.exportOptions || defaultExportOption;
            return {
                exportFields,
                exportOptions,
            };
        },
        getMapConfig() {
            return this.settings?.mapConfig || {};
        },
        getMapStatusList() {
            const defaultConfig = ["OPEN", "INPROGRESS", "RESOLVED", "REOPENED", "OVERDUE"];
            return this.settings?.mapConfig?.ticketStatusList || defaultConfig;
        },
        hasIssueTypeSuggestion() {
            // an extension account must be configured to use this feature
            return this.settings?.ticketConfig?.issueTypeSuggestion ?? false;
        },
        getDeviceLabelRule() {
            const options = {
                noSpaces: "^[a-zA-Z0-9\\-_]*$",
            };
            const rule = this.settings?.deviceConfig?.deviceLabelRule || null;
            return options[rule] || "^[\\s\\S]*$";
        },
        getLivePopupConfig() {
            return this.settings?.ticketConfig?.livePopup?.showLiveOnOpen ?? false;
        },
        getUserInactivityThreshold() {
            return this.settings?.appConfig?.userInactivityThreshold ?? 30;
        },
        getIOTDashCCTVConfigs() {
            return (
                this.settings?.iotDashboards?.cctvMulti?.alias || {
                    offline: "Offline",
                    videoLoss: "Video Loss",
                }
            );
        },
        /**@param {import("@/shared/services/table-service").CustomColumn[]} items  */
        updateIOTDashDVRTableCols(items) {
            try {
                const userOpts = this.settings?.iotDashboards?.cctvMulti?.dvrTableCols ?? {};
                items.forEach((v) => {
                    v.visible = userOpts[v.key] ?? v.visible;
                });
            } catch (error) {
                logger.error("Error when getting table headers from client config", { error });
            }
        },
    },
});
