import { logger } from "@/shared/services/common";
import httpClient from "@/shared/services/http-client";
import userService from "@/shared/services/user-service";
import { defineStore } from "pinia";
const ADMIN_ROLES = /**@type {const} */ (["sysadmin", "localadmin", "customeradmin"]);

export const useDashboardStore = defineStore("DashboardStore", {
    state: () => ({
        miniFyBreadCrumb: false,
        /**@type {import("@/types").Dashboard[]} */
        dashboards: [],
        loading: false,
        singleDashboard: false,
        breadCrumbTitle: "",
        dashboardFolderTree: [],
        hideBreadCrumb: false,
    }),
    getters: {},
    actions: {
        hideMainBreadCrumb(value) {
            this.hideBreadCrumb = value;
        },
        async fetchUserDashboards() {
            const user = userService.getLocalUser();
            const [role] = user.userRole;
            const { email } = user;

            ADMIN_ROLES.forEach((v) => {
                if (v == role) return;
            });

            return await this.getDashboardList(role, email);
        },
        handleBreadCrumbState(dash) {
            const value = dash?.config?.miniFyBreadCrumb ?? false;
            localStorage.setItem("miniFyBreadCrumb", value);
            this.miniFyBreadCrumb = value;

            const title = dash?.config?.titleAsBreadCrumb ? dash.name : "";
            localStorage.setItem("breadCrumbTitle", title);
            this.breadCrumbTitle = title;
        },
        async getDashboardList(role, email) {
            this.loading = true;
            try {
                let queryParams = "?orderBy=name&limit=50";
                const roleParam = "&assignedUserRoles=" + role + "&published=True";

                const response = await httpClient.get(`/iotapp/dashboards/${queryParams}${roleParam}`);

                /**@type {import("@/types").Dashboard[]} */
                const dashboards = response.data.contents;

                queryParams += "&assignedUsers=" + email + "&published=True";
                const res = await httpClient.get(`/iotapp/dashboards/${queryParams}`);
                if (res?.data?.contents) dashboards.push.apply(dashboards, res.data.contents);

                const uniqueDashboards = {};
                dashboards.forEach((dash) => {
                    uniqueDashboards[dash.id] = dash;
                });

                /**@type {import("@/types").Dashboard[]} */
                const userDashboards = Object.values(uniqueDashboards);
                if (userDashboards.length == 1) {
                    this.dashboards = userDashboards;
                    return this.isSingleComponent();
                } else {
                    await this.groupDashBoardsOnFolders(userDashboards);
                    return this.isSingleComponent();
                }
            } catch (err) {
                logger.error(err);
            } finally {
                this.loading = false;
            }
        },
        isSingleComponent() {
            const [firstDash] = this.dashboards;
            const isSingle = this.dashboards.length == 1;
            this.singleDashboard = isSingle;

            if (firstDash?.isFolder) {
                this.singleDashboard = false;
                return {
                    push: false,
                    item: firstDash,
                };
            }

            const isIframe = (() => {
                if (isSingle) {
                    return firstDash.config.type === "component" ? false : true;
                }
                return isSingle;
            })();

            const r = {
                push: this.singleDashboard && isIframe === false,
                item: firstDash,
            };
            return r;
        },
        async groupDashBoardsOnFolders(userDashboards) {
            try {
                // Fetching the response
                const response = await httpClient.get(`/iotapp/dashboards/folders/?limit=100`);

                // Building the tree hierarchy
                const folderDashboards = userDashboards
                    .filter((d) => d.folder)
                    .map((a) => {
                        a.parentId = a.folder.id;
                        return a;
                    });

                const folders = response.data.contents.map((f) => {
                    return { ...f, isFolder: true };
                });
                const baseTree = [...folders, ...folderDashboards].map((t) => {
                    return {
                        ...t,
                        uuid: this.uuid(),
                    };
                });
                this.dashboardFolderTree = this.buildHierarchy(baseTree);
                this.dashboards = [...this.dashboardFolderTree, ...userDashboards.filter((d) => !d.folder)];
            } catch (error) {
                console.error("Error in groupDashBoardsOnFolders:", error);
            }
        },
        buildHierarchy(data) {
            const map = new Map();
            const parentMap = new Map();
            let result = [];

            data.forEach((obj) => {
                map.set(obj.uuid, { ...obj, children: [] });
            });

            data.forEach((obj) => {
                if (obj.parentId !== null) {
                    if (!parentMap.has(obj.parentId)) {
                        parentMap.set(obj.parentId, []);
                    }
                    parentMap.get(obj.parentId).push(obj.uuid);
                }
            });

            data.forEach((obj) => {
                const mappedObj = map.get(obj.uuid);
                if (obj.parentId !== null) {
                    const parentObj = map.get(data.find((a) => a.id === obj.parentId && a.isFolder)?.uuid);
                    if (parentObj) {
                        parentObj.children.push(mappedObj);
                    }
                } else {
                    result.push(mappedObj);
                }
            });
            // Filter out empty folders
            function filterEmptyFolders(folders) {
                return folders.filter((folder) => {
                    if (folder.isFolder) {
                        folder.children = filterEmptyFolders(folder.children);
                        return folder.children.length > 0;
                    }
                    return true;
                });
            }

            result = filterEmptyFolders(result);
            return result;
        },
        findFolderById(data, targetId) {
            for (const item of data) {
                if (item.id == targetId && item.isFolder) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const found = this.findFolderById(item.children, targetId);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        },
        findDashboardByUrl(url, data = this.dashboards) {
            const stripQueryParams = (url) => url.split("?")[0];
            const urlWithoutQuery = stripQueryParams(url);
            const baseRegex = new RegExp(`^${urlWithoutQuery.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")}$`);
            let partialMatch = null;

            for (const item of data) {
                if (item.layouts) {
                    if (item.layouts.some((layout) => layout.src === url)) {
                        return { isExact: true, currentDashboard: item };
                    } else if (
                        !partialMatch &&
                        item.layouts.some((layout) => baseRegex.test(stripQueryParams(layout.src)))
                    ) {
                        partialMatch = item;
                    }
                }

                if (item.children && item.children.length > 0) {
                    const result = this.findDashboardByUrl(url, item.children);
                    if (result.isExact) {
                        return result;
                    } else if (!partialMatch) {
                        partialMatch = result.currentDashboard;
                    }
                }
            }

            return { isExact: false, currentDashboard: partialMatch };
        },

        uuid() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
                (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
            );
        },
    },
});
