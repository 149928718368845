import { and, isControl, rankWith } from "@jsonforms/core";
import labelRenderer from "./components/labelRenderer.vue";

export const entry = {
    renderer: labelRenderer,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "labelrenderer"),
    ),
};
