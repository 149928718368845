<template>
    <div class="container-fluid">
        <div class="footer fixed-bottom">
            <div class="flex-sb">
                <div class="flex" v-if="configStore.client?.sellerConfig?.logo">
                    <q-img
                        class="seller-image"
                        :src="configStore.client?.sellerConfig?.logo"
                        :alt="configStore.client?.sellerConfig?.name" />
                </div>
                <p class="mb-0 text-center">© Copyright {{ currentYear }} AM Technologies</p>
                <div class="flex">
                    <p>Powered by</p>
                    <img src="@/assets/images/vuelogix-logo-sm.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useConfigStore } from "@/store/configStore/configStore";
import moment from "moment";
export default {
    name: "FooterApp",
    setup() {
        const configStore = useConfigStore();
        const currentYear = moment().year();
        return { configStore, currentYear };
    },
};
</script>
<style scoped lang="scss">
.footer {
    padding: 5px;
    background-color: $white;
    border-top: solid 2px $border-primary;
    z-index: 999;
}
.footer p {
    font-size: 12px;
    font-weight: 500;
    color: $dark;

    @media screen and (max-width: 480px) {
        font-size: 10px;
    }
}
.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;

    @media screen and (max-width: 480px) {
        padding: 0px 10px;
        gap: 5px;
    }
}
.flex {
    gap: 10px;
    align-items: center;
    .seller-image {
        width: 50px;
    }
    @media screen and (max-width: 480px) {
        gap: 5px;
    }
}
</style>
