<template>
    <div
        v-if="!loading && !formNotFound"
        class="form-container attachment-wrap"
        :class="{ 'is-fullscreen': isFullScreen }">
        <div>
            <PreLoader :loading="fetchingData" :message="preloaderMessage" />

            <div class="form-info">
                <h1 class="form-title">{{ formTemplate.label }}</h1>
                <p class="desc">
                    {{ formTemplate.description }}
                </p>
            </div>
            <q-separator class="q-mb-md" />
            <json-forms
                :data="data"
                :renderers="renderers"
                :schema="schema"
                :uischema="UISchema"
                validationMode="ValidateAndHide"
                :additionalErrors="additionalErrors"
                @change="onChange" />
            <article class="error-loading" v-if="validationErrors.hasError">
                <aside class="flex-sb" v-if="validationErrors?.required">
                    <div class="flex">
                        <img src="@/assets/images/error-info.svg" alt="" />
                        Please Fill all the required fields
                    </div>
                </aside>
            </article>
        </div>
        <div class="button-controllers">
            <q-btn v-if="!hideCancelButton" @click="cancelForm" class="secondary-btn input">Cancel</q-btn>
            <q-btn
                @click="submitForm"
                :loading="submitLoading"
                :disable="additionalErrors.length > 0"
                class="primary-btn-sm input"
                >Submit</q-btn
            >
        </div>
    </div>
    <div v-else-if="loading && !formNotFound">
        <div>
            <q-card style="width: 100%" flat>
                <q-item>
                    <q-item-section>
                        <q-item-label>
                            <q-skeleton type="text" />
                        </q-item-label>
                        <q-item-label caption>
                            <q-skeleton type="text" />
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-skeleton height="200px" square />

                <q-card-actions align="right" class="q-gutter-md">
                    <q-skeleton type="QBtn" />
                    <q-skeleton type="QBtn" />
                </q-card-actions>
            </q-card>
        </div>
    </div>
    <div v-else-if="formNotFound" class="form-not-found">
        <button class="close-icon-secondary-sm" @click="cancelForm">
            <ToolTip>Close</ToolTip>
        </button>
        <img src="@/assets/images/image-404.svg" alt="no-image" />
        Form Not found
    </div>
</template>

<script>
import "@jsonforms/vue-vanilla/vanilla.css";
import "./utilities/style.scss";
import { useQuasar } from "quasar";
import { JsonForms } from "@jsonforms/vue";
import { fetchFormTemplateByName } from "@/shared/services/ApiServices/NPSApp";
import { defaultStyles, mergeStyles, vanillaRenderers } from "@jsonforms/vue-vanilla";
import customRenderers from "./renderers";
import httpClient from "@/shared/services/http-client";
import customValidator from "./validators";
import { CONSTANTS } from "@/shared/contants";
import toaster from "@/shared/services/alert-service";

const customStyles = mergeStyles(defaultStyles, {
    control: { input: "jf-input", select: "jf-input jf-select", label: "jf-input_label" },
});

const renderers = [...customRenderers, ...vanillaRenderers];

export default {
    components: {
        JsonForms,
    },
    props: {
        templateName: {
            type: String,
            required: true,
        },

        isFullScreen: {
            type: Boolean,
            default: false,
        },
        hideCancelButton: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const q = useQuasar();
        return { q };
    },
    data() {
        return {
            renderers: Object.freeze(renderers),
            data: {},
            currentObj: {},
            formTemplate: {},
            loading: true,
            templateId: null,
            schema: null,
            UISchema: null,
            validations: {},
            validationErrors: {
                hasError: false,
            },
            submitLoading: false,
            additionalErrors: [],
            files: [],
            attachments: null,
            renderCheck: false,
            getRenderName: "",
            multipleRender: [],
            multiplelabels: [],
            testdata: [],
            fetchingData: false,
            preloaderMessage: "",
            formNotFound: false,
        };
    },
    async mounted() {
        await this.fetchFormSchema();
        this.data = this.generateSchemaData();
        this.currentObj = this.data;
        this.validations.required = this.schema?.required || [];
        this.loading = false;
        await this.getFileRenderer(this.UISchema);
        await this.getlabelRenderer(this.UISchema);
    },
    methods: {
        async fetchFormSchema() {
            try {
                const response = await fetchFormTemplateByName(this.templateName);
                const data = response?.[0];
                if (!data) {
                    this.formNotFound = true;
                    return;
                }
                this.formTemplate = data;
                this.templateId = data.id;
                const metadata = data.metadata;
                this.schema = metadata.schema;
                this.UISchema = metadata.uiSchema;
                console.log(this.UISchema, "sss");
            } catch (error) {
                console.error(error);
            }
        },
        async getFileRenderer(uischema) {
            if (!uischema) return;
            if (uischema.elements) {
                uischema.elements.forEach((item) => {
                    if (item.type === "filerenderer") {
                        const scopeParts = item.scope.split("/");
                        this.getRenderName = scopeParts[scopeParts.length - 1];
                        const FileMultiple = scopeParts[scopeParts.length - 1];
                        this.multipleRender.push(FileMultiple);
                        this.renderCheck = true;
                        return;
                    }
                    if (item.elements) {
                        this.getFileRenderer(item);
                    }
                });
            }
        },

        async getlabelRenderer(uischema) {
            if (!uischema) return;
            if (uischema.elements) {
                uischema.elements.forEach((item) => {
                    if (item.type === "labelrenderer") {
                        const scopeParts = item.scope.split("/");
                        this.getRenderName = scopeParts[scopeParts.length - 1];
                        const FileMultiple = scopeParts[scopeParts.length - 1];
                        this.multiplelabels.push(FileMultiple);
                        console.log(this.multiplelabels, "labels");
                        return;
                    }
                    if (item.elements) {
                        this.getlabelRenderer(item);
                    }
                });
            }
        },
        generateSchemaData() {
            const data = {};
            for (const key in this.schema?.properties) {
                if (Object.prototype.hasOwnProperty.call(this.schema.properties, key)) {
                    const propertyType = this.schema.properties[key].type;
                    switch (propertyType) {
                        case "string":
                            data[key] = "";
                            break;
                        case "number":
                            data[key] = 0;
                            break;
                        case "object":
                            data[key] = {};
                            break;
                        case "array":
                            data[key] = [];
                            break;
                        default:
                            data[key] = null;
                    }
                }
            }
            return data;
        },
        onChange(event) {
            this.data = event.data;
            const lastModifiedFiled = this.findLastModifiedKey(this.data, this.currentObj);
            this.validateKey(lastModifiedFiled);
            this.currentObj = this.data;
        },
        findLastModifiedKey(obj1, obj2) {
            const keys = Object.keys(obj1);
            let lastModifiedKey = null;
            for (let key of keys) {
                if (obj1[key] !== obj2[key]) {
                    lastModifiedKey = key;
                }
            }

            return lastModifiedKey;
        },
        validateKey(key) {
            const schema = this.schema?.properties?.[key];
            if (!schema) {
                console.error(`No schema found for key: ${key}`);
                return;
            }
            this.additionalErrors = this.additionalErrors.filter((e) => e.instancePath !== `/${key}`);
            const schemaValidations = schema.validation || [];
            const isRequired = this.validations?.required?.includes(key) || false;
            const _validations = schemaValidations ? [schemaValidations] : [];

            if (isRequired) {
                _validations.push({ name: "required" });
            }

            const validationErrors = customValidator(_validations, key, this.data[key]);
            if (validationErrors) {
                this.additionalErrors.push(validationErrors);
            }
        },
        validateAll() {
            Object.keys(this.data).map((key) => {
                this.validateKey(key);
            });
        },
        async submitForm() {
            // Validate all fields and return if there are any errors
            this.validateAll();
            if (this.additionalErrors.length > 0) return;

            let hasFiles = false;
            let bodyFormData = new FormData();

            this.multipleRender.forEach((renderName) => {
                const fileFieldData = this.data[renderName];

                if (Array.isArray(fileFieldData) && fileFieldData.length > 0) {
                    hasFiles = true;
                    fileFieldData.forEach((file) =>
                        bodyFormData.append(`attachments`, file, `${renderName}_${file.name}`),
                    );
                }
            });

            if (hasFiles) {
                this.fetchingData = true;
                this.preloaderMessage = CONSTANTS.MSG_ATTACHMENT_UPLOADING;
            } else {
                this.fetchingData = true;
                this.preloaderMessage = CONSTANTS.MSG_FORM_CREATING;
            }

            try {
                if (this.renderCheck && hasFiles) {
                    const response = await httpClient.post(
                        `/ifmapp/dynamic-forms-attachments/`,
                        bodyFormData,
                        false,
                    );
                    if (response.status === 200) {
                        toaster.success(CONSTANTS.MSG_ATTACHMENT_SUCCESS);
                        this.data.attachments = response.data.contents;
                        await this.saveForm();
                    } else {
                        if (response.status === 413) {
                            toaster.error(CONSTANTS.ATTACHMENT_SIZE_FAIL);
                        } else {
                            toaster.error(CONSTANTS.MSG_ATTACHMENT_FAIL);
                        }
                        this.loading = false;
                    }
                } else {
                    await this.saveForm();
                }
            } catch (error) {
                console.error(error);
                toaster.error(CONSTANTS.MSG_ATTACHMENT_FAIL);
            } finally {
                this.fetchingData = false;
            }
        },

        async saveForm() {
            let formData = {
                formTemplate: parseInt(this.templateId),
                data: this.data,
            };

            Object.keys(formData.data).forEach((key) => {
                if (this.multiplelabels.includes(key)) {
                    delete formData.data[key];
                    console.log(`Deleted key: ${key}`);
                }
            });

            console.log(formData, "new");

            try {
                this.submitLoading = true;
                await httpClient.post(`/ifmapp/dynamic-forms/`, formData, false);
                this.q.notify({
                    type: "positive",
                    message: "Response submitted successfully!",
                    timeout: 1000,
                    textColor: "positive",
                    position: "center",
                    color: "white",
                    classes: "popup-notification",
                });
                this.$emit("hidePop");
            } catch (error) {
                console.error(error);
                this.q.notify({
                    type: "negative",
                    message: "Failed to submit response, Please try again later",
                    timeout: 1000,
                    textColor: "negative",
                    position: "center",
                    color: "white",
                    classes: "popup-notification",
                });
            } finally {
                this.submitLoading = false;
            }
        },
        cancelForm() {
            this.$emit("hidePop");
        },
    },

    provide() {
        return {
            styles: { ...customStyles },
        };
    },
};
</script>

<style lang="scss" scoped>
.form-container {
    display: grid;
    overflow: hidden;
    &.is-fullscreen {
        padding: 15px;
    }
    grid-template-rows: auto 50px;
    .form-info {
        padding-bottom: 15px;
        text-transform: capitalize;
        .form-title {
            font-size: 22px;
            font-weight: 600;
            color: $primary;
            @media (max-width: 480px) {
                font-size: 16px;
            }
        }
        p.desc {
            color: $secondary;
            font-size: 13px;
        }
    }
    .button-controllers {
        margin-top: 10px;
        width: 100%;
        display: flex;
        gap: 10px;
        .input {
            width: 100%;
        }
    }
}
.form-not-found {
    text-align: center;
    font-size: 14px;
    color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    gap: 10px;
    position: relative;

    .close-icon-secondary-sm {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
