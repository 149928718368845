import { entry as assetSelectRenderer } from "./assetSelectRenderer";
import { entry as deviceSelectRenderer } from "./deviceSelectRenderer";
import { entry as ButtonControlRenderer } from "./buttonRenderer";
import { entry as InputRenderer } from "./inputRenderer";
import { entry as selectRenderer } from "./selectRenderer";
import { entry as toggleRenderer } from "./toggleRenderer";
import { entry as durationRenderer } from "./durationRenderer";
import { entry as radioButtonRenderer } from "./radioButtonsRenderer";
import { entry as starRatingRenderer } from "./starRatingRenderer";
import { entry as dateTimeRenderer } from "./dateTimeRenderer";
import { entry as auditID } from "./randomIdRenderer";
import { entry as dateRangeRenders } from "./dateRangeRenders";
import { entry as fileRenderer } from "./fileRenderer";
import { entry as timeRenderer } from "./timeRenderer";
import { entry as multiLevelSelect } from "./multiLevelSelect";
import { entry as labelrenderer } from "./labelrenderer";

const customRenderers = [
    assetSelectRenderer,
    deviceSelectRenderer,
    ButtonControlRenderer,
    selectRenderer,
    InputRenderer,
    toggleRenderer,
    durationRenderer,
    radioButtonRenderer,
    starRatingRenderer,
    dateTimeRenderer,
    auditID,
    dateRangeRenders,
    fileRenderer,
    timeRenderer,
    multiLevelSelect,
    labelrenderer,
];

export default customRenderers;
