<template>
    <div>
        <div class="renderer-label-container">
            <p class="renderer-label" v-for="(label, index) in control?.schema?.labels" :key="index">
                {{ label }}
            </p>
        </div>
    </div>
</template>

<script>
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";

export default {
    props: {
        ...rendererProps(),
    },
    setup(props) {
        return useJsonFormsControl(props);
    },
};
</script>

<style lang="scss" scoped>
.renderer-label-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.renderer-label {
    margin: 0;
}
</style>
